
.App {
  text-align: center; 
  
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.before-after-line {
  display: inline-block;
  content: "";
  width: 2cm;
  height: 2px;
  background-color: #000;
  vertical-align: middle;
  margin: 0 0.5rem;
}

.swiper-container {
  width: 100%;
  height: auto;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #000e5d;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  z-index: 10;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color:#2e881cd2;
}

.swiper-button-next {
  right: 20px;
}

.swiper-button-prev {
  left: 20px;
}
.with-shadow {
  box-shadow: 0  6px 6px rgba(0, 0, 0, 0.3);
}
/* having a bouncng effect on the image*/
@keyframes bounce-moderate {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-bounce-moderate {
  animation-name: bounce-moderate;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

::-webkit-scrollbar{
  width: 0.25cm;
  height: 0cm;
  
}
::-webkit-scrollbar-track{
  background-color: #ecdbdb;
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb{
  background: linear-gradient(#2e881cd2,#000e5d );
  border-radius: 100vw;
 
}
::-webkit-scrollbar-thumb:hover{
  background-color:#000e5d ;
}
